import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { useForm, useFormContext, FormProvider } from 'react-hook-form';
import Modal from '@profesia/adamui/components/common/modal/Modal';
import { FormFooter } from '@profesia/adamui/components/common/modal';
import ImageCropper from '@profesia/adamui/components/common/ImageCropper';
import { useTranslationInPageLanguage, useGetLocale } from '@profesia/mark-offer-form/i18n';
import { useLogoContext } from '@profesia/mark-offer-form/services/LogoContext';
import { useCompanyDetailsUpdate } from '@profesia/mark-offer-form/services/companyDetails/useCompanyDetailsUpdate';
import useToastWithTranslation from '@profesia/mark-offer-form/services/useToastWithTranslation';
import useGetResolver from '@profesia/mark-offer-form/components/content/form/resolver';
import { ValidationSchema } from '@profesia/mark-offer-form/components/content/form/types';
import { FORM_COMPANY_LOGO_SOURCE_IMG, FORM_COMPANY_LOGO_CROPPER_DATA, FORM_COMPANY_LOGO_IMAGE_EXTENSION, FORM_COMPANY_LOGO_CROPPED_IMG, FORM_COMPANY_LOGO_IMAGE, FORM_COMPANY_ID, } from '@profesia/mark-offer-form/components/content/form/valuePaths';
import { prepareLogo } from './logoUtils/prepareLogo';
const EditLogoModal = ({ isOpen, onClose, onSubmit, isDirectAction = false, isModalSecond, sourceImgLogoUrl, initialCompanyData, }) => {
    const t = useTranslationInPageLanguage('companyDetails.editLogoModal');
    const commonT = useTranslationInPageLanguage('common');
    const buttonsT = useTranslationInPageLanguage('buttons');
    const currentLanguage = useGetLocale();
    const resolver = useGetResolver(ValidationSchema.Company);
    const toast = useToastWithTranslation();
    const { sourceImgLogo, cropData: contextCropData, setBase64Logo, setSourceImgLogo, setIsEdited, } = useLogoContext();
    const formMethods = isDirectAction
        ? useForm({
            defaultValues: { company: initialCompanyData },
            resolver: resolver,
        })
        : useFormContext();
    const { setValue, getValues, handleSubmit } = formMethods;
    const companyId = getValues(FORM_COMPANY_ID);
    const { mutate, isPending } = useCompanyDetailsUpdate((data) => {
        onSubmit(data);
        toast({ message: 'common.responseSuccess.saveDataSuccess', variant: 'success' });
    }, () => toast({ message: 'common.responseError.saveDataFailed', variant: 'error' }));
    const [editedLogo, setEditedLogo] = useState(null);
    const [initialCropData, setInitialCropData] = useState();
    const handleCropChange = (base64, imgExtension, cropData) => {
        setEditedLogo({
            croppedImg: base64,
            imgExtension,
            sourceImg: sourceImgLogo ?? '',
            cropperData: cropData,
        });
    };
    const handleUse = () => {
        setBase64Logo(editedLogo?.croppedImg ?? '');
        setSourceImgLogo(editedLogo?.sourceImg ?? '');
        setValue(FORM_COMPANY_LOGO_SOURCE_IMG, editedLogo?.sourceImg ?? '');
        setValue(FORM_COMPANY_LOGO_CROPPER_DATA, editedLogo?.cropperData);
        setValue(FORM_COMPANY_LOGO_IMAGE_EXTENSION, editedLogo?.imgExtension ?? '');
        setValue(FORM_COMPANY_LOGO_CROPPED_IMG, editedLogo?.croppedImg ?? '');
        setIsEdited(true);
    };
    const handleDelete = () => {
        setBase64Logo(null);
        setSourceImgLogo(null);
        setEditedLogo(null);
        setIsEdited(true);
        setValue(FORM_COMPANY_LOGO_IMAGE, null);
        onClose();
    };
    const onFormSubmit = (data) => {
        const croppedImgUrl = getValues(FORM_COMPANY_LOGO_CROPPED_IMG);
        const sourceImgUrl = getValues(FORM_COMPANY_LOGO_SOURCE_IMG);
        const finalLogo = prepareLogo(editedLogo, croppedImgUrl, sourceImgUrl);
        mutate({
            companyId,
            characteristics: data.company.characteristics.trim(),
            businessAreaId: data.company.businessArea.id,
            languageFlag: currentLanguage,
            logo: finalLogo,
        });
    };
    useEffect(() => {
        if (!isOpen)
            return;
        setInitialCropData(editedLogo?.cropperData ?? contextCropData);
    }, [isOpen, editedLogo, contextCropData]);
    const cropper = (_jsx(ImageCropper, { imageSrc: (sourceImgLogo || sourceImgLogoUrl) ?? '', mainLabel: t('companyLogo'), previewLabel: t('preview'), aspect: 2.4, onCropChange: handleCropChange, imgFormat: "jpeg", maxCropperBoxHeight: 264, maxPreviewBoxHeight: 100, initialCropData: initialCropData }));
    return (_jsx(Modal, { isOpen: isOpen, onClose: onClose, spacing: 4, isModalSecond: isModalSecond, title: t('title'), closeButtonText: commonT('actions.cancel'), shouldUseOffsetTop: false, content: isDirectAction ? _jsx(FormProvider, { ...formMethods, children: cropper }) : cropper, footer: _jsx(FormFooter, { isLoading: isPending, submitAction: () => {
                if (isDirectAction) {
                    handleUse();
                    setTimeout(() => {
                        handleSubmit(onFormSubmit)();
                    });
                }
                else {
                    handleUse();
                    onClose();
                }
            }, submitButtonText: isDirectAction ? buttonsT('save') : t('useButton'), leftSideActions: [
                {
                    label: t('deleteLogo'),
                    variant: 'destructive',
                    onClick: handleDelete,
                },
            ], onClose: onClose, closeButtonText: t('cancelButton') }) }));
};
export default EditLogoModal;
