import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { Grid } from '@mui/material';
import { Typography } from '@profesia/adamui/components/common/typography';
import { getConfiguration } from '@profesia/mark-offer-form/configuration';
import { useOptionsContext } from '@profesia/mark-offer-form/services/OptionsContext';
import { useGetFixedT, useGetLocale } from '@profesia/mark-offer-form/i18n';
import TextWithLineBreaks from '@profesia/adamui/components/common/text/TextWithLineBreaks';
import { getCompanyDetailsValuesFromForm } from '../getCompanyDetailsValuesFromForm';
import CompanyLogo from '../Form/CompanyLogo';
import EditLogoModal from '../EditLogoModal';
import CompanyDetailRow from './CompanyDetailRow';
const CompanyDetailsContent = ({ characteristics, businessAreaName, croppedImg, onSubmit, }) => {
    const t = useGetFixedT('companyDetails');
    const { form } = getConfiguration();
    const { isEditMode } = useOptionsContext();
    const { getValues } = useFormContext();
    const currentLanguage = useGetLocale();
    const initialCompanyData = getCompanyDetailsValuesFromForm(getValues, currentLanguage);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const handleOpenModal = () => {
        setIsModalOpen(true);
        if (isEditMode) {
            form.openModalThird();
        }
    };
    const handleCloseModal = () => {
        setIsModalOpen(false);
        if (isEditMode) {
            form.closeModalThird();
        }
    };
    return (_jsxs(Grid, { container: true, spacing: 2, rowSpacing: 1, "data-testid": "company-details-content", children: [_jsx(CompanyDetailRow, { label: t('mainFocus'), children: _jsx(Typography, { color: "primaryNavy", fontWeight: 700, children: businessAreaName }) }), _jsx(CompanyDetailRow, { label: t('briefDescription'), mt: { xs: 3, sm: 0 }, children: characteristics && (_jsx(Typography, { color: "primaryNavy", fontWeight: 700, children: _jsx(TextWithLineBreaks, { text: characteristics }) })) }), _jsxs(CompanyDetailRow, { label: t('companyLogo.label'), mt: { xs: 3, sm: 0 }, children: [_jsx(CompanyLogo, { handleOpenLogoModal: handleOpenModal, cropedImgLogoUrl: croppedImg ? croppedImg : '', isDirectAction: true }), _jsx(EditLogoModal, { onSubmit: onSubmit, initialCompanyData: initialCompanyData, onClose: handleCloseModal, isOpen: isModalOpen, sourceImgLogoUrl: croppedImg ? croppedImg : '', isDirectAction: true })] })] }));
};
export default CompanyDetailsContent;
