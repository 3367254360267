import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useRef, useState } from 'react';
import { Box, Stack, styled } from '@mui/material';
import { SimpleLink } from '@profesia/adamui/components/common/Link';
import { Typography } from '@profesia/adamui/components/common/typography';
import { getColor } from '@profesia/adamui/styles/colors';
import Label from '@profesia/adamui/components/common/Label';
import { useTranslationInPageLanguage } from '@profesia/mark-offer-form/i18n';
import { mobileDown } from '@profesia/adamui/styles/theme';
import { useLogoContext } from '@profesia/mark-offer-form/services/LogoContext';
const CompanyLogo = ({ handleOpenLogoModal, cropedImgLogoUrl, isDirectAction = false, }) => {
    const t = useTranslationInPageLanguage('companyDetails.companyLogo');
    const { base64Logo, sourceImgLogo, isEdited, setBase64Logo, setSourceImgLogo } = useLogoContext();
    const [displayLogoSrc, setDisplayLogoSrc] = useState(sourceImgLogo ?? cropedImgLogoUrl);
    const fileInputRef = useRef(null);
    const onSelectFile = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();
            reader.addEventListener('load', () => {
                setBase64Logo(reader.result?.toString() || '');
                setSourceImgLogo(reader.result?.toString() || '');
                handleOpenLogoModal?.();
            });
            reader.readAsDataURL(e.target.files[0]);
        }
    };
    const triggerFileInput = () => {
        if (fileInputRef.current) {
            fileInputRef.current.value = '';
            fileInputRef.current.click();
        }
    };
    if (isDirectAction) {
        return (_jsxs(_Fragment, { children: [cropedImgLogoUrl ? (_jsx(StyledImage, { src: cropedImgLogoUrl, alt: t('companyLogo') })) : (_jsx(_Fragment, { children: _jsx(SimpleLink, { variantType: "primary", fontWeight: "bold", onClick: triggerFileInput, mb: 0.5, "data-testid": "company-details-logo-upload", children: t('uploadLogoDirect') }) })), _jsx(Box, { display: 'none', children: _jsx("input", { ref: fileInputRef, "data-testid": "company-details-file-input", type: "file", accept: "image/png, image/jpeg", onChange: onSelectFile }) })] }));
    }
    return (_jsxs(Box, { "data-testid": "company-details-logo", children: [_jsxs(Stack, { direction: "row", justifyContent: "space-between", children: [_jsx(Label, { children: t('label') }), (base64Logo && isEdited) || (displayLogoSrc && !isEdited) ? (_jsx(SimpleLink, { variantType: "primary", variant: "body2", onClick: handleOpenLogoModal, children: t('editLink') })) : null] }), _jsx(StyledStack, { "data-testid": "company-details-logo-wrapper", children: (base64Logo && isEdited) || (displayLogoSrc && !isEdited) ? (_jsx(StyledImage, { src: (base64Logo || displayLogoSrc) ?? '', alt: t('label') })) : (_jsxs(_Fragment, { children: [_jsx(SimpleLink, { variantType: "primary", fontWeight: "bold", onClick: triggerFileInput, mb: 0.5, "data-testid": "company-details-logo-upload", children: t('uploadLogo') }), _jsx(Typography, { fontSize: 12, color: "neutralDark2", children: t('uploadLogoDescription') })] })) }), _jsx(Typography, { fontSize: 12, color: "neutralDark2", children: t('description') }), _jsx(Box, { display: 'none', children: _jsx("input", { ref: fileInputRef, "data-testid": "company-details-file-input", type: "file", accept: "image/png, image/jpeg", onChange: onSelectFile }) })] }));
};
const StyledImage = styled('img')(() => ({
    maxWidth: '240px',
    maxHeight: '100px',
    width: 'auto',
    height: 'auto',
}));
const StyledStack = styled(Stack)(({ theme: { palette, spacing } }) => ({
    height: '140px',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: getColor(palette.neutralLight0),
    border: `1px dotted ${getColor(palette.neutralLight5)}`,
    borderRadius: 4,
    marginTop: spacing(0.5),
    marginBottom: spacing(0.5),
    [mobileDown]: {
        padding: spacing(2),
    },
}));
export default CompanyLogo;
