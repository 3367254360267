import React, { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';
import { styled } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import { LoadingCircle } from '@profesia/adamui/components/common/loadings';
import {
    setSelectedCandidateId,
    setIsClickedCandidate,
    resetFullCandidatesData,
} from '../../../../../actions/JobDetailActions';
import fetchPipelineCandidates from '../api/fetchPipelineCandidates';
import ModalHeader from './Header';
import CandidateRow from './TableRow';
import JobCandidatesListSkeleton from '../../../../../skeletons/components/JobCandidatesListSkeleton';

export interface StageCandidatesModalProps {
    onClose: () => void;
    jobId: string;
    candidateIds: number[];
    stageName: string;
    stageId: number;
    stageCandidates: [];
}

export interface FullCandidate {
    id: number;
    firstname: string;
    surname: string;
    candidateName: string;
    addDate: string;
    profilePhoto: string | null;
    hasDuplicities: boolean;
    isClicked: boolean;
    stage: {
        id: number;
        name: string;
    };
}

interface RootState {
    noModalDashboardLogic: {
        noModalDashboardType: string;
        noModalDashboardIsOpen: boolean;
    };
    jobModel: {
        fullCandidates: FullCandidate[];
    };
    isFetchingPipelineCandidatesForJob: boolean;
    isSelectedCandidateIdInStage: number | null;
}

const StageCandidatesModal = ({
    onClose,
    jobId,
    candidateIds,
    stageName,
    stageId,
    stageCandidates,
}: StageCandidatesModalProps) => {
    const dispatch = useDispatch();
    const selectedCandidateId = useSelector(
        (state: RootState) => state.isSelectedCandidateIdInStage
    );

    const { isOpen, fullCandidates, isFetchingPipelineCandidatesForJob } = useSelector(
        (state: RootState) => ({
            fullCandidates: state.jobModel?.fullCandidates,
            isFetchingPipelineCandidatesForJob: state.isFetchingPipelineCandidatesForJob,
            isOpen: state.noModalDashboardLogic.noModalDashboardIsOpen,
        })
    );

    useEffect(() => {
        dispatch(setSelectedCandidateId(null));

        if (jobId && candidateIds.length > 0) {
            const initialIds = candidateIds.slice(0, 20);

            fetchPipelineCandidates(jobId, initialIds, dispatch, stageId, stageName);
        }
    }, [dispatch, jobId, candidateIds, stageId, stageName]);

    useEffect(() => {
        return () => {
            dispatch(resetFullCandidatesData());
        };
    }, []);

    const handleCandidateClick = useCallback(
        (candidateId: number) => {
            dispatch(setSelectedCandidateId(candidateId));
            dispatch(setIsClickedCandidate(candidateId, true));
        },
        [dispatch]
    );

    const loadMoreCandidates = useCallback(() => {
        if (isFetchingPipelineCandidatesForJob) return;

        const loadedCandidatesCount = fullCandidates?.length ?? 0;
        const nextIds = candidateIds.slice(loadedCandidatesCount, loadedCandidatesCount + 20);

        if (nextIds.length > 0) {
            fetchPipelineCandidates(jobId, nextIds, dispatch, stageId, stageName);
        }
    }, [
        jobId,
        candidateIds,
        fullCandidates,
        dispatch,
        isFetchingPipelineCandidatesForJob,
        stageId,
        stageName,
    ]);

    const modalClasses = ['noModal-wrapper', 'noModal-dashboard', isOpen ? 'show' : 'hide']
        .filter(Boolean)
        .join(' ');

    const candidatesCount = fullCandidates?.length ?? 0;

    return (
        <div className={modalClasses}>
            <ModalHeader onClose={onClose} stageName={stageName} />
            <div
                className="noModal__content modal__content"
                id="stageModalCandidatesListScrollable"
                style={{
                    overflowY: 'auto',
                    height: 'calc(100vh - 64px)',
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                {!fullCandidates ||
                (isFetchingPipelineCandidatesForJob && candidatesCount === 0) ? (
                    <JobCandidatesListSkeleton isVisibleControls={false} />
                ) : (
                    <div className="list__table" style={{ padding: '32px', marginBottom: '32px' }}>
                        <InfiniteScroll
                            className="overflow-default"
                            dataLength={candidatesCount}
                            next={loadMoreCandidates}
                            hasMore={candidatesCount < stageCandidates.length}
                            loader={
                                <LoaderContainer>
                                    <LoadingCircle />
                                </LoaderContainer>
                            }
                            scrollableTarget="stageModalCandidatesListScrollable"
                            style={{ overflow: 'visible' }}
                        >
                            <Table className="ui table unstackable">
                                <TableBody>
                                    {candidateIds.map((id) => {
                                        const candidate = fullCandidates.find(
                                            (candidate) =>
                                                candidate.id === id &&
                                                candidate.stage?.id === stageId
                                        );
                                        if (!candidate) return null;

                                        return (
                                            <CandidateRow
                                                key={candidate.id}
                                                candidate={candidate}
                                                isSelected={selectedCandidateId === candidate.id}
                                                onClick={() => handleCandidateClick(candidate.id)}
                                            />
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </InfiniteScroll>
                    </div>
                )}
            </div>
        </div>
    );
};

const LoaderContainer = styled('div')({
    display: 'flex',
    paddingTop: '16px',
});

export default StageCandidatesModal;
