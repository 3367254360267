import apiRequest from '../../../JobAdvertisement/apiRequest';
import {
    LOAD_PIPELINE_CANDIDATES_DATA_FOR_JOB,
    LOAD_PIPELINE_CANDIDATES_DATA_FOR_JOB_SUCCESS,
    LOAD_PIPELINE_CANDIDATES_DATA_FOR_JOB_FAILURE,
} from '../../../../../actions/JobDetailActions';
import { Dispatch, Action } from 'redux';

interface PipelineCandidateData {
    id: number;
    candidateName: string;
    profilePhoto: string | null;
    addDate: string;
    hasDuplicities: boolean;
    isClicked: boolean;
}

interface PipelineCandidatesResponse {
    fetchedPipelineCandidateIds: number[];
    pipelineCandidatesData: PipelineCandidateData[];
}

/**
 * Fetches pipeline candidates data for a job
 *
 * @param jobId - Job ID
 * @param candidateIds - Array of candidate IDs
 * @param dispatch - Redux dispatch function
 * @param stageId - Stage ID to assign to candidates
 * @param stageName - Stage name to assign to candidates
 */
const fetchPipelineCandidates = async (
    jobId: string,
    candidateIds: number[],
    dispatch: Dispatch<Action>,
    stageId?: number,
    stageName?: string
) => {
    dispatch({
        type: LOAD_PIPELINE_CANDIDATES_DATA_FOR_JOB,
        payload: {
            jobId,
            candidateIds,
        },
    });

    try {
        const response = await apiRequest<PipelineCandidatesResponse>(
            `position/${jobId}/pipeline/candidates`,
            {
                method: 'POST',
                body: JSON.stringify({ ids: candidateIds }),
            }
        );

        const candidatesArray = response.pipelineCandidatesData.map((candidate: any) => ({
            ...candidate,
            stage: {
                id: stageId || 0,
                name: stageName || '',
            },
            firstname: candidate.candidateName?.split(' ')[0] || '',
            surname: candidate.candidateName?.split(' ').slice(1).join(' ') || '',
        }));
        
        dispatch({
            type: LOAD_PIPELINE_CANDIDATES_DATA_FOR_JOB_SUCCESS,
            payload: {
                ...response,
                pipelineCandidatesData: candidatesArray,
            },
        });

        return response;
    } catch (error) {
        dispatch({
            type: LOAD_PIPELINE_CANDIDATES_DATA_FOR_JOB_FAILURE,
            error,
        });

        throw error;
    }
};

export default fetchPipelineCandidates;
