import { FORM_COMPANY_CHARACTERISTICS, FORM_COMPANY_BUSINESS_AREA, FORM_COMPANY_LOGO_IMAGE_EXTENSION, FORM_COMPANY_LOGO_SOURCE_IMG_URL, FORM_COMPANY_LOGO_CROPPER_DATA, FORM_COMPANY_LOGO_CROPPED_IMG_URL, FORM_COMPANY_ID, } from '@profesia/mark-offer-form/components/content/form/valuePaths';
export const getCompanyDetailsValuesFromForm = (getValues, lang) => {
    const characteristics = getValues(FORM_COMPANY_CHARACTERISTICS) ?? {};
    const businessArea = getValues(FORM_COMPANY_BUSINESS_AREA) ?? {};
    const id = getValues(FORM_COMPANY_ID) ?? 0;
    const logo = {
        sourceImg: getValues(FORM_COMPANY_LOGO_SOURCE_IMG_URL) ?? null,
        imgExtension: getValues(FORM_COMPANY_LOGO_IMAGE_EXTENSION) ?? null,
        cropperData: getValues(FORM_COMPANY_LOGO_CROPPER_DATA) ?? undefined,
        croppedImg: getValues(FORM_COMPANY_LOGO_CROPPED_IMG_URL) ?? null,
    };
    return {
        id,
        characteristics: characteristics?.[lang] ?? '',
        businessArea: {
            id: businessArea?.id ?? 0,
            name: businessArea?.names?.[lang] ?? '',
        },
        logo,
    };
};
