import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { SimpleLink } from '@profesia/adamui/components/common/Link';
import { SectionWrapperColorVariant } from '@profesia/adamui/components/common/types';
import { useMobile } from '@profesia/adamui/utils/responsive';
import BoxedSectionWrapper from '@profesia/mark-offer-form/components/content/form/components/BoxedSectionWrapper';
import { useGetFixedT, useTranslationInPageLanguage, useGetLocale, } from '@profesia/mark-offer-form/i18n';
import { useOptionsContext } from '@profesia/mark-offer-form/services/OptionsContext';
import { useLogoContext } from '@profesia/mark-offer-form/services/LogoContext';
import { getConfiguration } from '@profesia/mark-offer-form/configuration';
import { FORM_COMPANY_ID, FORM_COMPANY_BUSINESS_AREA, FORM_COMPANY_CHARACTERISTICS, FORM_COMPANY_LOGO_IMAGE_EXTENSION, FORM_COMPANY_LOGO_SOURCE_IMG, FORM_COMPANY_LOGO_CROPPER_DATA, FORM_COMPANY_LOGO_CROPPED_IMG, } from '@profesia/mark-offer-form/components/content/form/valuePaths';
import { getCompanyDetailsValuesFromForm } from './getCompanyDetailsValuesFromForm';
import CompanyModal from './Modal';
import CompanyDetailsContent from './Content';
const CompanyDetails = () => {
    const currentLanguage = useGetLocale();
    const t = useGetFixedT('companyDetails');
    const pageT = useTranslationInPageLanguage('companyDetails');
    const isMobile = useMobile('sm');
    const editLink = pageT(isMobile ? 'links.editLinkShort' : 'links.editLinkFull');
    const { isEditMode } = useOptionsContext();
    const { form } = getConfiguration();
    const { setBase64Logo, setSourceImgLogo, setCropData } = useLogoContext();
    const { getValues, setValue, reset } = useFormContext();
    const initialCompanyData = useMemo(() => getCompanyDetailsValuesFromForm(getValues, currentLanguage), [getValues, currentLanguage]);
    const [company, setCompany] = useState(initialCompanyData);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const openModal = () => {
        setIsModalOpen(true);
        if (isEditMode)
            form.openModalSecond();
    };
    const closeModal = () => {
        setIsModalOpen(false);
        if (isEditMode)
            form.closeModalSecond();
    };
    const handleSubmitData = (data) => {
        const updatedLogo = data.logo
            ? {
                sourceImg: data.logo.sourceImg ?? null,
                imgExtension: data.logo.imgExtension ?? null,
                cropperData: data.logo.cropperData,
                croppedImg: data.logo.croppedImg ?? null,
            }
            : null;
        setCompany({
            id: getValues(FORM_COMPANY_ID),
            characteristics: data.characteristics,
            businessArea: {
                id: data.businessArea.id,
                name: data.businessArea.names[data.languageFlag],
            },
            logo: updatedLogo,
        });
        setValue(FORM_COMPANY_CHARACTERISTICS, {
            ...getValues(FORM_COMPANY_CHARACTERISTICS),
            [data.languageFlag]: data.characteristics,
        });
        setValue(FORM_COMPANY_BUSINESS_AREA, data.businessArea);
        reset({
            FORM_COMPANY_CHARACTERISTICS,
            FORM_COMPANY_BUSINESS_AREA,
            FORM_COMPANY_LOGO_IMAGE_EXTENSION,
            FORM_COMPANY_LOGO_SOURCE_IMG,
            FORM_COMPANY_LOGO_CROPPER_DATA,
            FORM_COMPANY_LOGO_CROPPED_IMG,
        }, { keepValues: true, keepDirty: true });
        closeModal();
    };
    useEffect(() => {
        setBase64Logo(company.logo?.croppedImg ?? null);
        setSourceImgLogo(company.logo?.sourceImg ?? null);
        if (company.logo?.cropperData) {
            setCropData(company.logo.cropperData);
        }
    }, []);
    useEffect(() => {
        const characteristics = getValues(FORM_COMPANY_CHARACTERISTICS);
        const businessArea = getValues(FORM_COMPANY_BUSINESS_AREA);
        setCompany((prev) => ({
            ...prev,
            characteristics: characteristics[currentLanguage],
            businessArea: {
                id: businessArea.id,
                name: businessArea.names[currentLanguage],
            },
        }));
    }, [currentLanguage]);
    return (_jsxs(_Fragment, { children: [_jsx(BoxedSectionWrapper, { title: t('title'), endComponent: _jsx(SimpleLink, { variantType: "primary", variant: "body2", onClick: openModal, children: editLink }), variant: SectionWrapperColorVariant.Dark, children: _jsx(CompanyDetailsContent, { onSubmit: handleSubmitData, characteristics: company.characteristics, businessAreaName: company.businessArea.name, croppedImg: company.logo?.croppedImg ?? null }) }), isModalOpen && (_jsx(CompanyModal, { company: { company }, onSubmit: handleSubmitData, onClose: closeModal, isOpen: isModalOpen }))] }));
};
export default CompanyDetails;
