export const prepareLogo = (logo, croppedImgUrl, sourceImgUrl) => {
    if (!logo)
        return null;
    const croppedImg = logo.croppedImg === croppedImgUrl ? null : (logo.croppedImg ?? null);
    const sourceImg = logo.sourceImg === sourceImgUrl ? null : (logo.sourceImg ?? null);
    return {
        ...logo,
        croppedImg,
        sourceImg,
    };
};
