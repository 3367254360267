import { format } from 'date-fns';
import { AutocompleteItem } from '@profesia/adamui/components/common/input/types';
import {
    FormT,
    PositionPlaceTypeId,
    Skill,
} from '@profesia/mark-offer-form/components/content/form/types';
import { SuitableFor, LanguageRequirements } from '@profesia/mark-offer-form/codebooks';
import { PostData } from './types';

export const mapFormToPostData = (data: FormT): PostData => {
    const hasAddress = (data: FormT) => !!data.positionPlace?.address;
    const hasHybridOrOnsiteWorkplace =
        data.positionPlace?.type === PositionPlaceTypeId.Hybrid ||
        data.positionPlace?.type === PositionPlaceTypeId.Onsite;
    const hasTravelWorkplace = data.positionPlace?.type === PositionPlaceTypeId.Travel;

    return {
        title: data.jobTitle,
        language: data.language,
        positions: data.jobPositions.map((position: AutocompleteItem) => ({
            positionId: Number(position.id),
            categoryId: Number(position.categoryId),
        })),
        jobTypes: data.employmentTypes,
        description: data.jobDescription,
        educationLevel: Number(data.educationLevel),
        workplaceTypeId: data.positionPlace?.type,
        place:
            hasAddress(data) && hasHybridOrOnsiteWorkplace
                ? data.positionPlace?.address[0]?.value
                : undefined,
        startDate: data.positionPlace?.startDate,
        skills: data.skills?.others.map((item: Skill<string>) => ({
            skillId: Number(item.id),
            levelId: Number(item.level),
            skillName: item.value || '',
        })),
        regions: hasTravelWorkplace
            ? data.positionPlace?.regions?.map((region: AutocompleteItem) => ({
                  regionId: Number(region.id),
              }))
            : [],
        personalityRequirements: data.skills?.personalSkills,
        benefits: data.benefits,
        educationSpecialization: data.educationSpecialization,
        refugeeFriendly: data.offerSuitableFor?.includes(SuitableFor.Refugees) ?? false,
        suitableForHighSchoolStudent:
            data.offerSuitableFor?.includes(SuitableFor.HighSchoolStudents) ?? false,
        suitableForUniversityStudent:
            data.offerSuitableFor?.includes(SuitableFor.UniversityStudents) ?? false,
        validForGraduate: data.offerSuitableFor?.includes(SuitableFor.Graduates) ?? false,
        drivingLicences: data.skills?.drivingLicence,
        experienceInField: data.skills?.experienceAtPosition,
        experienceYears: data.skills?.yearsOfExperience,
        googlePlaceId:
            hasAddress(data) && hasHybridOrOnsiteWorkplace
                ? data.positionPlace?.address[0]?.id
                : undefined,
        allLanguagesRequired: data.languages?.required === LanguageRequirements.All,
        languageSkills: data.languages?.items.map((item: Skill<string>) => ({
            languageId: Number(item.id),
            levelId: Number(item.level),
        })),
        salary: {
            currencyId: isNaN(Number(data.salary?.currency)) ? null : Number(data.salary?.currency),
            salaryPeriodId: isNaN(Number(data.salary?.period)) ? null : Number(data.salary?.period),
            minimumValue:
                (data.salary?.type === 'fixed'
                    ? data.salary?.value?.toString()
                    : data.salary?.from?.toString()) ?? null,
            maximumValue:
                (data.salary?.type === 'fixed' ? null : data.salary?.to?.toString()) ?? null,
            publishMaximumValue: (data.salary?.type !== 'fixed' && data.salary?.showTo) ?? false,
            additionalInformation: data.salaryOtherInformation ?? '',
        },
        noteForCandidate: data.noteForCandidate,
        numberOfOpenings: data.numberOfOpenings ? Number(data.numberOfOpenings) : 1,
        applicableTill: data.applicableTill
            ? format(data.applicableTill as Date, 'yyyy-MM-dd')
            : '',
        applyWithoutResume: data.isApplicableWithoutCv,
        newCandidatesNotification: data.shouldBeNotifiedAboutNewResumes,
        referenceNumber: data.referenceNumber,
        ...(data.contactPersonId && { userId: data.contactPersonId }),
    };
};
